import { Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

const M3TitleMedium = ({children, ...props}) =>{
    const { isSmallDevice } = useDeviceStatus();

    return (
        <Typography fontSize={isSmallDevice? "14px": "16px"} fontWeight={500} lineHeight={isSmallDevice?"20px": "24px"} letterSpacing={isSmallDevice?"0.1px":"0.15px"} color={ColorsConst.ON_SURFACE} {...props}>
            {children}
        </Typography>
    )
}

export default M3TitleMedium;