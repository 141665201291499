import { styled, Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

// const M3LabelLargeTypography = styled(Typography)(({ children, ...props }) => (
//     <Typography fontSize={"14px"} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.1px"} {...props}>
//         {children}
//     </Typography>
// ));

const M3LabelLargeTypography = ({children, ...props}) =>{
    const { isSmallDevice } = useDeviceStatus();

    return (
        <Typography fontSize={isSmallDevice ? "12px": "14px"} fontWeight={500} lineHeight={isSmallDevice? "16px": "20px"} letterSpacing={isSmallDevice ? "0.5px": "0.1px"} color={ColorsConst.ON_SURFACE} {...props}>
            {children}
        </Typography>
    )
}

export default M3LabelLargeTypography;