import {
  AppBar,
  Box,
  Toolbar,
  ButtonBase,
  IconButton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ColorsConst from "../../utils/ColorsConst";
import MiliLogo from "../../assets/img/mili_logo.png";
import MiliNote from "../../assets/img/mili_note_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import MenuIcon from "@mui/icons-material/Menu";
import { setAddMeetingToggle } from "../../redux/reducers/appSlice";
import { useLocation } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";
import Mixpanel, { EventNames } from "../../utils/analytics/mixpanel";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";
import styles from './header.module.scss';

const Header = (props) => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const onStartTakingNotes = () => {
    Mixpanel.track(EventNames.BUTTON_CLICKED, {
      'Button Type': 'Primary',
      'Screen': location.pathname,
      'Section': 'Meetings',
      'Button Name': 'New meeting',
    });
    dispatch(setAddMeetingToggle(true));
  };
  const meetingList = useSelector((state: RootState) => state.app.meetingList);
  const { isExtension, isSmallDevice, isMobile } = useDeviceStatus();

  const onMenuIconClick = () => {
    props.setIsDrawerOpen((val) => !val);
  };

  // const showNotesBtn =
  //   !isSmallDevice && location.pathname === "/all-meetings" && meetingList.upcoming?.length;

  const showNotesBtn = (location.pathname === "/all-meetings" || location.pathname === "/shared-meetings")
  return (
    <AppBar
      sx={{
        backgroundColor: ColorsConst.DARK_SURFACE_CONTAINER,
        zIndex: 10,
      }}
    >
      <Toolbar
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Box alignItems="center" display="flex" sx={{position:"relative", left:isSmallDevice?"-8px":"unset", top:isSmallDevice?"4px":"unset"}}>
          {isMobile && (
            <IconButton onClick={onMenuIconClick} sx={{ mr: "6px", position:"relative", top: "-1px" }}>
              <MenuIcon sx={{ color: ColorsConst.DARK_PRIMARY }} />
            </IconButton>
          )}
          <img className={styles.logo} src={MiliLogo} alt="mili_logo"  height={isSmallDevice ? 28 : 32} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {showNotesBtn ? (
            <ButtonBase
              sx={{
                bgcolor: ColorsConst.DARK_PRIMARY_CONTAINER,
                borderRadius: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: "20px",
                py: 1,
              }}
              onClick={onStartTakingNotes}
            >
              <EditNoteIcon
                sx={{ color: ColorsConst.DARK_ON_PRIMARY_CONTAINER }}
              />{" "}
              <Typography
                variant="subtitle2"
                sx={{ color: ColorsConst.DARK_ON_PRIMARY_CONTAINER, ml: 0.5 }}
              >
                Create Meeting
              </Typography>
            </ButtonBase>
          ) : null}
          {/* <IconButton>
            <SearchIcon style={{ color: ColorsConst.ON_SURFACE_VARIANT }} />
          </IconButton>
          {isExtension && (
            <IconButton>
              <IndeterminateCheckBoxOutlinedIcon
                style={{ color: ColorsConst.ON_SURFACE_VARIANT }}
              />
            </IconButton>
          )} */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
