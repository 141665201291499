import { Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

const M3BodyMediumTypography = ({children, ...props}) =>{
    const { isSmallDevice } = useDeviceStatus();

    return (
        <Typography fontSize={isSmallDevice ? "12px" :"14px"} lineHeight={isSmallDevice?"16px":"20px"} letterSpacing={isSmallDevice?"0.5px":"0.25px"} color={ColorsConst.ON_SURFACE} {...props}>
            {children}
        </Typography>
    )
}

export default M3BodyMediumTypography;