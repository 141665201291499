import { Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

const M3HeadlineSmall = ({children, ...props}) => {
    const { isSmallDevice } = useDeviceStatus();

    return (
        <Typography fontSize={isSmallDevice?"16px" :"24px"} lineHeight={isSmallDevice?"24px": "32px"} color={ColorsConst.ON_SURFACE} {...props}>
            {children}
        </Typography>
    )
}

export default M3HeadlineSmall;