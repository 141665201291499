import { Typography } from "@mui/material";
import ColorsConst from "../../utils/ColorsConst";
import { useDeviceStatus } from "../../hooks/useDeviceStatus";

const M3BodySmall = ({children, ...props}) =>{
    const { isSmallDevice } = useDeviceStatus();

    return (
        <Typography fontSize={isSmallDevice ? "9px":"12px"} lineHeight={isSmallDevice?"12px": "16px"} letterSpacing={"0.4px"} color={ColorsConst.ON_SURFACE} {...props}>
            {children}
        </Typography>
    )
}

export default M3BodySmall;